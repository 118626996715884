import { gql } from '@apollo/client';
import { AssignmentFields, InvoiceDocumentsFields, InvoiceDteTypeFields, InvoiceIdFields } from '../invoices/fragments';
import { MasterEntityBasicField, CompanyDebtsSummary, RiskBlacklistFields } from '../customers/fragment';
import { MoneyFields } from '../moneyFields';
import { FileFields } from '../fileField';

export const GET_ROSTERS = gql`
  query GetRosters(
    $first: Int
    $offset: Int
    $orderBy: String
  ) {
    getRosters(
      first: $first
      offset: $offset
      orderBy: $orderBy
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          id
          status
          recesionDate
          invoicesCount
          invoicesAmount {
            ... MoneyFields
          }
          loanedInvoicesCount
          cessionCertificate {
            ... FileFields
          }
          completedCessions
          failedCessions
          pendingCessions
          fund {
            id
            masterEntity {
              id
              name
              rut
              displayNationalIdentifier
              riskBlacklist {
                ...RiskBlacklistFields
                comments
                createdAt
              }
            }
          }
          cessionFund {
            id
            masterEntity {
              id
              name
              rut
              displayNationalIdentifier
              riskBlacklist {
                ...RiskBlacklistFields
                comments
                createdAt
              }
            }
          }
        }
      }
    }
  }
  ${MoneyFields}
  ${FileFields}
  ${RiskBlacklistFields}
`;

export const GET_FUNDS = gql`
  query GetFunds{
    getFunds{
      id
      rut @client
      socialReason @client
      masterEntity {
        id
        rut
        name
      }
      email
      isAsignee
      isFund
    }
  }
`;

export const GET_LOAN_MANAGERS = gql`
  query GetLoanManagers (
    $invoiceLoanRosterId: Int
    $first: Int
    $offset: Int
    $orderBy: String
  ) {
    getLoanManagers (
      invoiceLoanRosterId: $invoiceLoanRosterId
      first: $first
      offset: $offset
      orderBy: $orderBy
    ) {
      totalCount
      totalPages
      edges {
        node {
          id
          monthlyRate
          retentionRate
          transferAmount {
            ...MoneyFields
          }
          financedAmount {
            ...MoneyFields
          }
          priceDifference {
            ...MoneyFields
          }
          expirationDate
          status
          document {
            ... InvoiceIdFields
            dateToPay
            dteType {
              ... InvoiceDteTypeFields
            }
            documents {
              ... InvoiceDocumentsFields
            }
            company {
              id
              masterEntity {
                ... MasterEntityBasicField
                riskBlacklist {
                  ...RiskBlacklistFields
                }
              }
            }
            receiver {
              ... MasterEntityBasicField
              riskBlacklist {
                ...RiskBlacklistFields
              }
            }
          }
          assignment {
            ... AssignmentFields
          }
          invoiceLoanRoster {
            id
            recesionDate
          }
        }
      }
    }
  }
  ${MasterEntityBasicField}
  ${InvoiceDteTypeFields}
  ${InvoiceIdFields}
  ${InvoiceDocumentsFields}
  ${MoneyFields}
  ${AssignmentFields}
  ${RiskBlacklistFields}
`;

export const SEND_ROSTER_INVOICE_DOCUMENTS = gql`
  query sendRosterInvoiceDocuments(
    $id: String!
  ) {
    sendRosterInvoiceDocuments(
      id: $id
    ) {
      success
    }
  }
`;

export const INVOICE_LOAN_TRANSFER_INFO = gql`
  query invoiceLoansMoneyTransfers {
    invoiceLoansMoneyTransfers {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          id
          totalAmount {
            ...MoneyFields
          }
          interestAssociated {
            ...MoneyFields
          }
          invoiceLoanManagers {
            id
            document {
              id
              company {
                id
                name
                rut
              }
              folio
              amountWithIva {
                ...MoneyFields
              }
            }
          }
          relatedRosterFund {
            id
            name
            rut
            displayNationalIdentifier
            riskBlacklist {
              ...RiskBlacklistFields
              comments
              createdAt
            }
            ...CompanyDebtsSummary
          }
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${MoneyFields}
  ${RiskBlacklistFields}
`;
