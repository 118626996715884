/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { UserIdentification } from '../user/fragments';

export const MailLogTypeFields = gql`
  fragment MailLogTypeFields on MailLogType {
    id
    to
    fromEmail {
      ... UserIdentification
    }
    sendGridData {
      id
      events {
        id
        status
        eventTime
      }
    }
  }
  ${UserIdentification}
`;

export const MailParametersTypeFields = gql`
  fragment MailParametersTypeFields on MailParametersType {
    id
    country {
      id
      name
    }
    requestingPlatform {
      id
      code
    }
    mailTemplate {
      id
      version
      template
    }
    fromEmail {
      id
      email
    }
    to {
      id
      email
    }
    bcc {
      id
      email
    }
    cc {
      id
      email
    }
  }
`;

export const WhatsappLogFields = gql`
  fragment WhatsappLogFields on WhatsappLogType {
    id
    body
    inbox
    createdAt
    whatsapplogfileSet {
      id
      contentType
      file {
        name
        url
      }
    }
    lastEvent {
      id
      whatsappLogEvent {
        id
        status
      }
    }
  }
`;

export const WhatsappConversationFields = gql`
  fragment WhatsappConversationFields on WhatsappConversationType {
    id
    fromPhoneNumber
    conversationHash
    twilioSender {
      id
    }
    relatedUser {
      id
      firstName
      lastName
      usercompanySet {
        company {
          masterEntity {
            id
            name
            displayNationalIdentifier
          }
          executiveAssigned {
            id
            firstName
            lastName
          }
        }
      }
    }
    lastMessageSeen
    lastMessageDatetime
  }
`;
