import { gql } from '@apollo/client';
import { InvoiceTransferFields, InvoiceRatesFields, InvoicePaymentDiscountFields, InvoiceMoneyTransferFields } from '../fragments';

export const REQUEST_MONEY_TRANSFERS = gql`
  mutation RequestMoneyTransfers(
    $documentsIds: [Int]!, 
    $modelLabel: String!,
    $treasuryMessageId: String!
    ) {
    requestMoneyTransfers(
      documentsIds: $documentsIds, 
      modelLabel: $modelLabel,
      treasuryMessageId: $treasuryMessageId,
      ) {
      success
    }
  }
`;

export const MANUAL_MONEY_TRANSFERS = gql`
  mutation manualMoneyTransfer(
    $invoiceIds:[Int]!,
    $transferDatetime: Date!
  ) {
    manualMoneyTransfer(
      invoiceIds: $invoiceIds
      transferDatetime: $transferDatetime
    ) {
      invoices {
        ... InvoiceTransferFields
        ... InvoicePaymentDiscountFields
        ... InvoiceMoneyTransferFields
        ... InvoiceRatesFields
      }
    }
  }
  ${InvoiceTransferFields} 
  ${InvoiceMoneyTransferFields}
  ${InvoicePaymentDiscountFields}
  ${InvoiceRatesFields} 
`;

export const GENERATE_TREASURY_MESSAGE = gql`
  mutation GenerateTreasuryMessage($documentsIds: [Int]!, $modelLabel: String!) {
    generateTreasuryMessage(documentsIds: $documentsIds, modelLabel: $modelLabel) {
      createdObject {
        id
        message
      }
    }
  }
`;

export const REJECT_MONEY_TRANSFERS = gql`
  mutation RejectMoneyTransfers($documentsIds: [Int]!, $modelLabel: String!) {
    rejectMoneyTransfers(documentsIds: $documentsIds, modelLabel: $modelLabel) {
      success
    }
  }
`;

export const DELETE_TREASURY_MESSAGE = gql`
  mutation DeleteTreasuryMessage($id: String!) {
    deleteTreasuryMessage(id: $id) {
      success
    }
  }
`;

export const REQUEST_INVOICE_LOAN_MONEY_TRANSFER = gql`
  mutation requestInvoiceLoanMoneyTransfer($id: String!) {
    requestInvoiceLoanMoneyTransfer(id: $id) {
      success
    }
  }
`;
