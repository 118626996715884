import { gql } from '@apollo/client';
import { RiskPredictionResultTypeFragment, RiskPredictionVariableContributionModelTypeFragment } from '../backoffice_risk/fragments';
import { CollectionActionsFields, CollectionManagerBasicFields, CollectionPriorityFields, DataForCollectionFields } from '../collection/fragments';
import { CompanyCessionsHistoryFields,
  CompanyDocumentsFields,
  CompanyReceiverCessionsFields,
  ComplianceFields,
  MasterEntityBasicField,
  ReceiverCessionsHistoryFields,
  CompanyDebtsSummary,
  ReceiverDebtsSummary,
  CompanyRestrictions,
  RiskBlacklistFields,
} from '../customers/fragment';
import { PreofferEvaluationInfo } from '../evaluation_requests/fragments';
import {
  CountryFields,
  InvoiceBasicFields,
  InvoiceCollectionFields,
  InvoiceComplianceFields,
  InvoiceFilesFields,
  InvoiceMoneyTransferFields,
  InvoiceOfferRatesFields,
  InvoicePaymentDiscountFields,
  InvoiceRatesFields,
  InvoiceRatificationFields,
  InvoiceReintegrationFields,
  InvoiceReplacementFields,
  InvoiceTransferFields,
  MailInvoicesFields,
  OrderingAssignedFields,
  RatificationManagerFields,
  TypeConnectionFields,
  sharedInvoiceInput,
  sharedInvoiceInputFields,
} from '../fragments';
import { FundBasicField, InvoiceLoanManagerBasicField, InvoiceLoanRosterBasic } from '../invoice-loan/fragments';
import { MoneyFields } from '../moneyFields';
import { RatificationActionsBasicFields } from '../ratification/fragments';
import { UserIdentification } from '../user/fragments';
import {
  CessionsFields, CollectionManagerFields,
  CreditNoteFields,
  DocumentFinanceStateFields,
  DownloadInvoiceDocumentFields,
  InvoiceAttachableFields, InvoiceDetailFields, InvoiceDocumentsFields, InvoiceDteTypeFields,
  InvoiceHistoryStatusFields,
  InvoiceIdFields,
  InvoiceIntermediateReferenceFields,
  InvoiceLoanedFields,
  InvoiceReferenceFields,
  InvoiceStatusFields,
  OfferFields,
  PreofferBasicFields,
  PreofferFields,
  TraceFields,
} from './fragments';

export const SIMPLE_INVOICE = gql`
  query GetSimpleInvoice($invoiceId: ID!) {
    invoice(invoiceId: $invoiceId) {
      ... InvoiceIdFields,
      company {
        id
        masterEntity {
          ... MasterEntityBasicField
        }
      }
      receiver {
        ... MasterEntityBasicField
      }
    }
  }
  ${InvoiceIdFields}
  ${MasterEntityBasicField}
`;

export const USER_INVOICE_PROFILE = gql`
  query userInvoiceProfile($invoiceId: ID!) {
    invoice(invoiceId: $invoiceId) {
      ... InvoiceIdFields
      ... InvoiceStatusFields
      ... InvoiceIntermediateReferenceFields
      ... InvoiceAttachableFields
      ... InvoiceLoanedFields
      ...DownloadInvoiceDocumentFields
      providerTrace @client {
        id
        estimatedPaymentDate
        paymentDate
        comments
        isAutomatic
        eventTime: date
        status {
          id
          code
          status
        }
        bank {
          id
          name
        }
      }
      providerRejectionReasons @client {
        id
        estimatedPaymentDate
        paymentDate
        comments
        isAutomatic
        eventTime: date
        status {
          id
          code
          status
        }
        bank {
          id
          name
        }
      }
      paymentMethod @client
      dteType {
        ... InvoiceDteTypeFields
      }
      invoicedetail {
        ... InvoiceDetailFields
      }
      company {
        id
        masterEntity {
          ... MasterEntityBasicField
          country {
            ... CountryFields
          }
        }
      }
      receiver {
        ... MasterEntityBasicField
        country {
          ... CountryFields
        }
        company {
          id
          hasInvoiceProviderCredentials
        }
      }
      traces {
        ... TraceFields
      }
      cessions {
        ... CessionsFields
      }
      uniqueDocumentFinanceState {
        ... DocumentFinanceStateFields
      }
      binnacleSet {
        id
        message
        username
        mail
        username
      }
      mailInvoices {
        id
        mailParameter {
          mail {
            id
            emailName
          }
        }
        updatedAt
        sendGridData {
          id
          toEmail
          events {
            id
            createdAt
            status
          }
        }
      }
    }
  }
  ${InvoiceIdFields}
  ${InvoiceDteTypeFields}
  ${InvoiceStatusFields}
  ${InvoiceIntermediateReferenceFields}
  ${MasterEntityBasicField}
  ${TraceFields}
  ${CessionsFields}
  ${DocumentFinanceStateFields}
  ${InvoiceAttachableFields}
  ${InvoiceLoanedFields}
  ${InvoiceDetailFields}
  ${CountryFields}
  ${DownloadInvoiceDocumentFields}
`;

export const GET_INVOICE = gql`
  query GetInvoice($invoiceId: ID!) {
    invoice(invoiceId: $invoiceId) {
      ...InvoiceBasicFields
      ...InvoiceFilesFields
      ...InvoiceCollectionFields
      ...InvoiceRatificationFields
      ...InvoiceRatesFields
    }
  }
  ${InvoiceBasicFields}
  ${InvoiceFilesFields}
  ${InvoiceCollectionFields}
  ${InvoiceRatificationFields}
  ${InvoiceRatesFields}
`;

export const GET_INVOICE_PROFILE = gql`
  query GetInvoice($invoiceId: ID!) {
    invoice(invoiceId: $invoiceId) {
      ...InvoiceBasicFields
      ...InvoiceFilesFields
      ...InvoiceCollectionFields
      ...InvoiceRatificationFields
      ...InvoiceReplacementFields
      ...InvoiceReintegrationFields
      ...InvoiceRatesFields
      ...OrderingAssignedFields
      ...InvoiceIntermediateReferenceFields
      ...DownloadInvoiceDocumentFields
    }
  }
  ${InvoiceBasicFields}
  ${InvoiceFilesFields}
  ${InvoiceCollectionFields}
  ${InvoiceRatificationFields}
  ${InvoiceReplacementFields}
  ${InvoiceReintegrationFields}
  ${InvoiceIntermediateReferenceFields}
  ${InvoiceRatesFields}
  ${OrderingAssignedFields}
  ${DownloadInvoiceDocumentFields}
`;

export const MY_DOCUMENTS = gql`
  query myDocuments(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceBasicFields
        }
      }
      flatInvoices @client {
        ... InvoiceBasicFields
      }
    }
  }
  ${TypeConnectionFields} 
  ${InvoiceBasicFields} 
`;

export const MY_SALES_PURCHASES = gql`
  query mySalesPurchases(${sharedInvoiceInputFields}) {
    invoices(${sharedInvoiceInput}) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          dteType {
            ... InvoiceDteTypeFields
          }
          company {
            id
            masterEntity {
              ... MasterEntityBasicField
            }
          }
          receiver {
            ... MasterEntityBasicField
          }
          cessions {
            ... CessionsFields
          }
          uniqueDocumentFinanceState {
            ... DocumentFinanceStateFields
          }
          mailInvoices {
            ... MailInvoicesFields
          }
          ... InvoiceReferenceFields
        }
      }
    }
  }
  ${TypeConnectionFields}
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${InvoiceDteTypeFields}
  ${MasterEntityBasicField}
  ${InvoiceReferenceFields}
  ${CessionsFields}
  ${DocumentFinanceStateFields}
  ${MailInvoicesFields}
`;

export const INVOICE_CREATE_OPERATION = gql`
  query invoiceCreateOperation(${sharedInvoiceInputFields}) {
    invoices(${sharedInvoiceInput}) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          offer {
            ... OfferFields
          }
        }
      }
    }
  }
  ${TypeConnectionFields}
  ${InvoiceIdFields}
  ${OfferFields}
`;

export const AVAILABLE_FACTORING = gql`
  query availableFactoring(${sharedInvoiceInputFields}) {
    invoices(${sharedInvoiceInput}) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          ... InvoiceReferenceFields
          preofferSelectable @client
          isForOrdering
          hasPaymentsEmitted
          invoiceStatus @client
          amounts @client
          lightningPaymentAuthorized @client
          receiver {
            ... MasterEntityBasicField
          }
          company {
            id
            masterEntity {
              ... MasterEntityBasicField
            }
            executiveAssigned {
              ... UserIdentification
            }
          }
          dteType {
            ... InvoiceDteTypeFields
          }
          preoffer {
            ... PreofferFields
          }
          documents {
            ... InvoiceDocumentsFields
          }
          uniqueDocumentFinanceState {
            ... DocumentFinanceStateFields
          }
          cessions {
            ... CessionsFields
          }
        }
      }
    }
  }
  ${TypeConnectionFields}
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${InvoiceDteTypeFields}
  ${InvoiceDocumentsFields}
  ${PreofferFields}
  ${MasterEntityBasicField}
  ${InvoiceReferenceFields}
  ${DocumentFinanceStateFields}
  ${CessionsFields}
  ${UserIdentification}
`;

export const EVALUATING_FACTORING = gql`
  query availableFactoring(${sharedInvoiceInputFields}) {
    invoices(${sharedInvoiceInput}) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          ... InvoiceReferenceFields
          preofferSelectable @client
          isForOrdering
          hasPaymentsEmitted
          invoiceStatus @client
          amounts @client
          lightningPaymentAuthorized @client
          receiver {
            ... MasterEntityBasicField
          }
          company {
            id
            masterEntity {
              ... MasterEntityBasicField
            }
            executiveAssigned {
              ... UserIdentification
            }
          }
          dteType {
            ... InvoiceDteTypeFields
          }
          preoffer {
            ... PreofferFields
          }
          documents {
            ... InvoiceDocumentsFields
          }
          uniqueDocumentFinanceState {
            ... DocumentFinanceStateFields
          }
          cessions {
            ... CessionsFields
          }
          restrictedBy {
              ...CompanyRestrictions
          }
        }
      }
    }
  }
  ${TypeConnectionFields}
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${InvoiceDteTypeFields}
  ${InvoiceDocumentsFields}
  ${PreofferFields}
  ${MasterEntityBasicField}
  ${InvoiceReferenceFields}
  ${DocumentFinanceStateFields}
  ${CessionsFields}
  ${CompanyRestrictions}
  ${UserIdentification}
`;

export const AVAILABLE_OFFERS = gql`
  query availableOffers(${sharedInvoiceInputFields}) {
    invoices(${sharedInvoiceInput}) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          ... InvoiceReferenceFields
          preofferSelectable @client
          invoiceStatus @client
          amounts @client
          lightningPaymentAuthorized @client
          receiver {
            ... MasterEntityBasicField
          }
          dteType {
            ... InvoiceDteTypeFields
          }
          preoffer {
            ... PreofferFields
          }
          offer {
            ... OfferFields
          }
          documents {
            ... InvoiceDocumentsFields
          }
          cessions {
            ... CessionsFields
          }
        }
      }
    }
  }
  ${TypeConnectionFields}
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${InvoiceDteTypeFields}
  ${InvoiceDocumentsFields}
  ${PreofferFields}
  ${OfferFields}
  ${MasterEntityBasicField}
  ${InvoiceReferenceFields}
  ${CessionsFields}
`;

export const CLIENT_ASSIGNMENTS = gql`
  query clientAssignments(${sharedInvoiceInputFields}) {
    invoices(${sharedInvoiceInput}) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          ... InvoiceReferenceFields
          ... InvoicePaymentDiscountFields
          receiver {
            ... MasterEntityBasicField
          }
          collectionManager {
            ... CollectionManagerFields
          }
          offer {
            ... OfferFields
            transferCondition
          }
          cessions {
            ... CessionsFields
          }
          historystatusSet {
            ...InvoiceHistoryStatusFields
          }
          lastHistoryStatus{
            ...InvoiceHistoryStatusFields
          }
        }
      }
    }
  }
  ${TypeConnectionFields}
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${MasterEntityBasicField}
  ${CollectionManagerFields}
  ${InvoiceReferenceFields}
  ${OfferFields}
  ${CessionsFields}
  ${InvoiceHistoryStatusFields}
  ${InvoicePaymentDiscountFields}
`;

export const INVOICE_ATTACHABLE_DOCUMENTS = gql`
  query InvoiceAttachableDocuments($invoiceId: ID!) {
    invoice(invoiceId: $invoiceId) {
      id
      folio
      ... InvoiceAttachableFields
      company {
        id
        name
        rut
        documents {
          ... CompanyDocumentsFields
        }
      }
    }
  }
  ${InvoiceAttachableFields}
  ${CompanyDocumentsFields}
`;

export const MY_OFFERS = gql`
  query myOffers(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceBasicFields
          ... InvoiceRatesFields
          receiver {
            invoicesActualPaymentTerm
          }
          restrictedBy {
            ...CompanyRestrictions
          }
        }
      }
      flatInvoices @client {
        ... InvoiceBasicFields
        ... InvoiceRatesFields
      }
    }
  }
  ${TypeConnectionFields} 
  ${InvoiceBasicFields}
  ${InvoiceRatesFields}
  ${CompanyRestrictions}
`;

export const OFFERS_COMMERCIAL = gql`
  query offersCommercial(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          ... InvoiceAttachableFields
          isForOrdering
          hasPaymentsEmitted
          invoiceStatus @client
          lightningPaymentAuthorized @client
          company {
            id
            name
            masterEntity {
              ... MasterEntityBasicField
              riskBlacklist {
                ...RiskBlacklistFields
              }
              ...CompanyDebtsSummary
            }
            currentCommissionRules {
              id
              threshold
              variant
              value
            }
            companyevaluationrestrictionsSet {
              ...CompanyRestrictions
            }
            documents {
              ... CompanyDocumentsFields
            }
            executiveAssigned {
              ... UserIdentification
            }
          }
          receiver {
            ... MasterEntityBasicField
            riskBlacklist {
              ...RiskBlacklistFields
            }
            ...ReceiverDebtsSummary
          }
          dteType {
            ... InvoiceDteTypeFields
          }
          uniqueDocumentFinanceState {
            ... DocumentFinanceStateFields
          }
          offer {
            ... OfferFields
          }
          preoffer {
            ... PreofferBasicFields
          }
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${ReceiverDebtsSummary}
  ${TypeConnectionFields} 
  ${InvoiceIdFields}
  ${InvoiceStatusFields} 
  ${InvoiceDteTypeFields} 
  ${MasterEntityBasicField}
  ${OfferFields}
  ${PreofferBasicFields}
  ${DocumentFinanceStateFields}
  ${CompanyDocumentsFields}
  ${CompanyRestrictions}
  ${InvoiceAttachableFields}
  ${UserIdentification}
  ${RiskBlacklistFields}
  `;
export const MY_PREOFFERS_COMMERCIAL = gql`
  query myOffersCommercial(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          ... CreditNoteFields
          ... InvoiceAttachableFields
          preofferSelectable @client
          lightningPaymentAuthorized @client
          invoiceStatus @client
          company {
            id
            executiveAssigned {
              ... UserIdentification
            }
            masterEntity {
              ... MasterEntityBasicField
              riskBlacklist {
                ...RiskBlacklistFields
              }
              ...CompanyDebtsSummary
            }
            currentCommissionRules {
              id
              threshold
              variant
              value
            }
          }
          receiver {
            ... MasterEntityBasicField
            riskBlacklist {
              ...RiskBlacklistFields
            }
            ...ReceiverDebtsSummary
          }
          dteType {
            ... InvoiceDteTypeFields
          }
          uniqueDocumentFinanceState {
            id
            comments
            status {
              id
              code
            }
          }
          preoffer {
            ... PreofferFields
          }
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${ReceiverDebtsSummary}
  ${TypeConnectionFields} 
  ${InvoiceIdFields}
  ${InvoiceStatusFields} 
  ${InvoiceDteTypeFields} 
  ${MasterEntityBasicField}
  ${UserIdentification}
  ${PreofferFields}
  ${CreditNoteFields}
  ${InvoiceAttachableFields}
  ${RiskBlacklistFields}
`;

export const PENDING_SIGNATURE = gql`
  query pendingSignature(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          invoiceStatus @client
          company {
            id
            rut
            name
            executiveAssigned {
              ... UserIdentification
            }
            masterEntity {
              ... MasterEntityBasicField
            }
          }
          receiver {
            ... MasterEntityBasicField
          }
          pendingsignaturecontractannexinvoice {
            id
            pendingSignatureDocument {
              id
              globalAppId
              createdAt
              lastFile
            }
          }
        }
      }
    }
  }
  ${TypeConnectionFields} 
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${MasterEntityBasicField}
  ${UserIdentification}
`;

export const INVOICE_TRANSFER_INFO = gql`
  query getInvoiceTreasuryFields(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceComplianceFields
          ... InvoiceTransferFields
          ... InvoicePaymentDiscountFields
          ... InvoiceMoneyTransferFields
          ... InvoiceRatesFields
          intermediateReferencesFrom {
            toInvoice {
            ... InvoiceIdFields
            dteType {
              ... InvoiceDteTypeFields
              }
            }
            amount {
              ... MoneyFields
            }
          }
          creditNotes @client {
            ... InvoiceIdFields
            dteType {
              ... InvoiceDteTypeFields
            }
          }
          debitNotes @client {
            ... InvoiceIdFields
            dteType {
              ... InvoiceDteTypeFields
            }
          }
          isFirstOperation
          isForOrdering
          hasPaymentsEmitted
          collectionManager {
            id
          }
          company {
            masterEntity {
              ...CompanyDebtsSummary
            }
          }
          receiver {
            ...ReceiverDebtsSummary
          }
        }
      }
      flatInvoices @client {
        ... InvoiceTransferFields
        ... InvoiceMoneyTransferFields
        ... InvoicePaymentDiscountFields
        ... InvoiceRatesFields
        isFirstOperation
        isForOrdering
      }
    }
  }
  ${CompanyDebtsSummary}
  ${ReceiverDebtsSummary}
  ${TypeConnectionFields} 
  ${InvoiceComplianceFields}
  ${InvoiceTransferFields} 
  ${InvoiceIdFields}
  ${MoneyFields}
  ${InvoiceDteTypeFields}
  ${InvoiceMoneyTransferFields}
  ${InvoicePaymentDiscountFields}
  ${InvoiceRatesFields} 
`;

export const INVOICE_AMOUNT_DETAILS = gql`
  query getInvoiceAmountsDetails(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceBasicFields
          ... InvoicePaymentDiscountFields
          ... InvoiceCollectionFields

        }
      }
      flatInvoices @client {
        ... InvoiceBasicFields
        ... InvoicePaymentDiscountFields
        ... InvoiceCollectionFields

      }
    }
  }
  ${InvoiceCollectionFields} 
  ${TypeConnectionFields} 
  ${InvoiceBasicFields} 
  ${InvoicePaymentDiscountFields}
`;

export const MY_ASSIGNMENTS = gql`
  query myAssignments(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          ... InvoiceAttachableFields
          ... InvoiceReferenceFields
          ... InvoiceRatificationFields
          isFunding @client
          preofferSelectable @client
          lightningPaymentAuthorized @client
          invoiceStatus @client
          isRatificated
          isForOrdering
          hasPaymentsEmitted
          isFirstOperation
          company {
            id
            name
            masterEntity {
              ... MasterEntityBasicField
              compliance {
                ... ComplianceFields
              }
              riskBlacklist {
                ...RiskBlacklistFields
              }
              ...CompanyDebtsSummary
            }
            companyevaluationrestrictionsSet {
              ...CompanyRestrictions
            }
            documents {
              ... CompanyDocumentsFields
            }
            legalRepresentative {
              id
              relatedPerson {
                ... MasterEntityBasicField
                compliance {
                  ... ComplianceFields
                }
              }
            }
            executiveAssigned {
              ... UserIdentification
            }
          }
          receiver {
            ... MasterEntityBasicField
            riskBlacklist {
              ...RiskBlacklistFields
            }
            ...ReceiverDebtsSummary
          }
          offer {
            ... OfferFields
          }
          invoiceloanmanager {
            ... InvoiceLoanManagerBasicField
            invoiceLoanRoster {
              ... InvoiceLoanRosterBasic
              fund {
                ... FundBasicField
                masterEntity {
                  ... MasterEntityBasicField
                }
              }
            }
          }
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${ReceiverDebtsSummary}
  ${TypeConnectionFields} 
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${MasterEntityBasicField}
  ${ComplianceFields}
  ${InvoiceLoanManagerBasicField}
  ${InvoiceLoanRosterBasic}
  ${FundBasicField}
  ${InvoiceAttachableFields}
  ${InvoiceReferenceFields}
  ${CompanyDocumentsFields}
  ${OfferFields}
  ${InvoiceRatificationFields}
  ${CompanyRestrictions}
  ${UserIdentification}
  ${RiskBlacklistFields}
`;

export const PENDING_DOCUMENTS_INVOICES = gql`
  query pendingDocuments(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          ... InvoiceAttachableFields
          ... InvoiceReferenceFields
          ... InvoiceRatificationFields
          isFunding @client
          preofferSelectable @client
          lightningPaymentAuthorized @client
          invoiceStatus @client
          isRatificated
          isForOrdering
          hasPaymentsEmitted
          isFirstOperation
          company {
            id
            name
            masterEntity {
              ... MasterEntityBasicField
              compliance {
                ... ComplianceFields
              }
              riskBlacklist {
                ...RiskBlacklistFields
              }
              ...CompanyDebtsSummary
            }
            companyevaluationrestrictionsSet {
              ...CompanyRestrictions
            }
            documents {
              ... CompanyDocumentsFields
            }
            legalRepresentative {
              id
              relatedPerson {
                ... MasterEntityBasicField
                compliance {
                  ... ComplianceFields
                }
              }
            }
            executiveAssigned {
              ... UserIdentification
            }
          }
          receiver {
            ... MasterEntityBasicField
            riskBlacklist {
              ...RiskBlacklistFields
            }
            ...ReceiverDebtsSummary
          }
          preoffer {
            ... PreofferFields
          }
          invoiceloanmanager {
            ... InvoiceLoanManagerBasicField
            invoiceLoanRoster {
              ... InvoiceLoanRosterBasic
              fund {
                ... FundBasicField
                masterEntity {
                  ... MasterEntityBasicField
                }
              }
            }
          }
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${ReceiverDebtsSummary}
  ${TypeConnectionFields} 
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${MasterEntityBasicField}
  ${ComplianceFields}
  ${InvoiceLoanManagerBasicField}
  ${InvoiceLoanRosterBasic}
  ${FundBasicField}
  ${InvoiceAttachableFields}
  ${InvoiceReferenceFields}
  ${CompanyDocumentsFields}
  ${PreofferFields}
  ${InvoiceRatificationFields}
  ${CompanyRestrictions}
  ${UserIdentification}
  ${RiskBlacklistFields}
`;

export const COLLECTION_INVOICES = gql`
  query collectionInvoices(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceBasicFields
          ... InvoiceCollectionFields
          ... InvoiceRatificationFields
          ... InvoiceFilesFields
          ... InvoiceRatesFields
          ... InvoicePaymentDiscountFields
          company {
            masterEntity {
              ...CompanyDebtsSummary
            }
          }
          receiver {
            ...ReceiverDebtsSummary
          }
        }
      }
      flatInvoices @client {
        ... InvoiceBasicFields
        ... InvoiceCollectionFields
        ... InvoiceFilesFields
        ... InvoiceRatesFields
      }
    }
  }
  ${CompanyDebtsSummary}
  ${ReceiverDebtsSummary}
  ${TypeConnectionFields} 
  ${InvoiceBasicFields} 
  ${InvoiceCollectionFields} 
  ${InvoiceFilesFields} 
  ${InvoiceRatesFields}
  ${InvoiceRatificationFields}
  ${InvoicePaymentDiscountFields}
`;

export const INVOICES_IN_COLLECTION = gql`
  query invoicesInCollection(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          ... CreditNoteFields
          isFunding @client
          lightningPaymentAuthorized @client
          isRatificated
          hasPaymentsEmitted
          dteType {
            ... InvoiceDteTypeFields
          }
          traces {
            ... TraceFields
          }
          uniqueDocumentFinanceState {
            ... DocumentFinanceStateFields
          }
          company {
            id
            masterEntity {
              ... MasterEntityBasicField
              riskBlacklist {
                ...RiskBlacklistFields
              }
            }
            executiveAssigned {
              ... UserIdentification
            }
          }
          receiver {
            ... MasterEntityBasicField
            riskBlacklist {
              ...RiskBlacklistFields
            }
          }
          preoffer {
            ... PreofferFields
          }
          invoiceloanmanager {
            ... InvoiceLoanManagerBasicField
            invoiceLoanRoster {
              ... InvoiceLoanRosterBasic
              fund {
                ... FundBasicField
                masterEntity {
                  ... MasterEntityBasicField
                }
              }
            }
          }
          collectionManager {
            ... CollectionManagerBasicFields
            owner {
              id
            }
            collectionPriority {
              ... CollectionPriorityFields
            }
            actions {
              ... CollectionActionsFields 
              author {
                ... UserIdentification
              }
            }
            collector {
              ... UserIdentification
            }
            currentDataForCollection {
              ... DataForCollectionFields
            }
          }
          ratificationmanager {
            id
            actions {
              id
              actionType
              comment
              createdAt
              channel
              responded
              author {
                ... UserIdentification
              }
            }
          }
          invoiceDebt {
            id
            days
             debt {
              ...MoneyFields
             }
          }
          offer {
            id
            monthlyRate
            defaultRate
            retentionRate
            transferCondition
            fundingDaysAfterExpiration
          }
          ... InvoicePaymentDiscountFields
        }
      }
    }
  }
  ${TypeConnectionFields}
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${CollectionManagerBasicFields}
  ${DataForCollectionFields}
  ${CollectionPriorityFields}
  ${CreditNoteFields}
  ${InvoiceDteTypeFields}
  ${PreofferFields}
  ${TraceFields}
  ${DocumentFinanceStateFields}
  ${MasterEntityBasicField}
  ${InvoiceLoanManagerBasicField}
  ${InvoiceLoanRosterBasic}
  ${FundBasicField}
  ${UserIdentification}
  ${CollectionActionsFields}
  ${InvoicePaymentDiscountFields}
  ${RiskBlacklistFields}
`;

export const HISTORIC_INVOICES = gql`
  query finishedInvoices(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceOfferRatesFields
          ... InvoiceCollectionFields
          status
          isFunding @client
          lightningPaymentAuthorized @client
          uniqueDocumentFinanceState {
            id
            comments
          }
          company {
            id
            rut
            masterEntity {
              ... MasterEntityBasicField
              riskBlacklist {
                ...RiskBlacklistFields
              }
              ...CompanyDebtsSummary
            }
          }
          receiver {
            ... MasterEntityBasicField
            riskBlacklist {
              ...RiskBlacklistFields
            }
            ...ReceiverDebtsSummary
          }
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${ReceiverDebtsSummary}
  ${TypeConnectionFields} 
  ${InvoiceIdFields}
  ${InvoiceOfferRatesFields}
  ${MasterEntityBasicField}
  ${InvoiceCollectionFields}
  ${RiskBlacklistFields}
`;

export const RECOMMENDED_INVOICES = gql`
  query recommendedInvoices(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceBasicFields
          ... InvoiceRatesFields
        }
      }
      flatInvoices @client {
        ... InvoiceBasicFields
      }
    }
  }
  ${TypeConnectionFields}
  ${InvoiceBasicFields} 
  ${InvoiceRatesFields}
`;

export const EXPORT_INVOICES = gql`
query
  exportInvoices(
    $rowCount: Int
    $extraFields: [String]
    $exportType: String
    ${sharedInvoiceInputFields}
  ) {
    exportInvoices(
      rowCount: $rowCount
      extraFields: $extraFields
      exportType: $exportType
      ${sharedInvoiceInput}
    )}
`;

export const GET_CLIENT_DEBTORS_LIST_EXCEL = gql`
  query getClientDebtorsListExcel($clientRut: String!){
    getClientDebtorsListExcel(clientRut: $clientRut){
      url
    }
  }
`;

const defaultFragment = `
  companyId: $companyId
  currency: $currency
  availableForOrdering: $availableForOrdering
  availableForTransfer: $availableForTransfer
  availableForReintegration: $availableForReintegration
  availableForReplacement: $availableForReplacement
  recommended: $recommended
  globalFilter: $globalFilter
  first: $first
`;

export const COUNT_FACTORING = gql`
  query CountFactoring(
    $companyId: Int
    $currency: String
    $availableForOrdering: Boolean = false
    $availableForTransfer: Boolean = false
    $availableForReintegration: Boolean = false
    $availableForReplacement: Boolean = false
    $recommended: Boolean = false
    $globalFilter: String = ""
    $first: Int = 1
    $evaluatingIssuedGte: Date
  ) {
    preoffers: invoices(
      availableForFinancing: "AVAILABLE",
      preoffer_Preofferevaluationrequest_Isnull: true,
      ${defaultFragment}
    ) {
      ... TypeConnectionFields
      edges {
        node {
          id
        }
      }
    }
    offers: invoices(
      hasValidOffer: true
      ${defaultFragment}
    ) {
      ... TypeConnectionFields
      edges {
        node {
          id
        }
      }
    }
    cessions: invoices(
      hasBeenCeded: true
      ${defaultFragment}
    ) {
      ... TypeConnectionFields
      edges {
        node {
          id
        }
      }
    }
    evaluations: invoices(
      preoffer_Preofferevaluationrequest_Status_In: ["Evaluating", "Rejected", "PendingDocuments", "DocumentsReviewed"]
      dateIssued_Gte: $evaluatingIssuedGte
      ${defaultFragment}
    ) {
      ... TypeConnectionFields
      edges {
        node {
          id
        }
      }
    }
  }
  ${TypeConnectionFields}
`;

export const HOME_RECEIVED_DATA = gql`
query HomeReceivedData(
  $dateIssued_Gte: Date
  $companyId: Int
  $dteType_CountryId: Int
) {
  rejectedInvoices: invoices(
    dateIssued_Gte: $dateIssued_Gte
    companyId: $companyId
    siiStatus: ["Rejected"]
  ) {
    ... TypeConnectionFields
    aggregate(field: "amount_with_iva")
    edges {
      cursor
      node {
        id
      }
    }
  }
  receivedInvoices: invoices(
    dateIssued_Gte: $dateIssued_Gte
    receiverId: $companyId
    dteType_CountryId: $dteType_CountryId
  ) {
    ... TypeConnectionFields
    aggregate(field: "amount_with_iva")
    edges {
      cursor
      node {
        id
      }
    }
  }
  creditNotesInvoices: invoices(
    dateIssued_Gte: $dateIssued_Gte
    companyId: $companyId
    dteType_Code_In: ["61"]
  ) {
    ... TypeConnectionFields
    aggregate(field: "amount_with_iva")
    edges {
      cursor
      node {
        id
      }
    }
  }
}
${TypeConnectionFields}
`;

export const SUPPLIER_CHART = gql`
  query supplierGraph(
    ${sharedInvoiceInputFields}
  ){
    supplierGraph(
    ${sharedInvoiceInput}
    )
  }
`;

export const INVOICES_RATIFICATION = gql`
  query invoicesRatification(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          ... CreditNoteFields
          lightningPaymentAuthorized @client
          operationalRoute
          company {
            id
            executiveAssigned {
              ... UserIdentification
            }
            masterEntity {
              ... MasterEntityBasicField
              riskBlacklist {
                ...RiskBlacklistFields
              }
              ...CompanyDebtsSummary
            }
          }
          receiver {
            ... MasterEntityBasicField
            riskBlacklist {
              ...RiskBlacklistFields
            }
            ...ReceiverDebtsSummary
          }
          dteType {
            ... InvoiceDteTypeFields
          }
          ratificationmanager {
            ... RatificationManagerFields
            ratificationPriority {
              ... CollectionPriorityFields
            }
          }
          traces {
            ... TraceFields
          }
          uniqueDocumentFinanceState {
            ... DocumentFinanceStateFields
          }
          preoffer {
            id
            preofferevaluationrequest {
              id
              paymentDate
            }
          }
          invoiceloanmanager {
            ... InvoiceLoanManagerBasicField
            invoiceLoanRoster {
              ... InvoiceLoanRosterBasic
              fund {
                ... FundBasicField
                masterEntity {
                  ... MasterEntityBasicField
                }
              }
            }
          }
          amountUsedInEntityLine {
            ... MoneyFields
          }
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${ReceiverDebtsSummary}
  ${TypeConnectionFields} 
  ${InvoiceIdFields}
  ${InvoiceStatusFields} 
  ${InvoiceDteTypeFields} 
  ${MasterEntityBasicField}
  ${RatificationManagerFields}
  ${UserIdentification}
  ${TraceFields}
  ${CollectionPriorityFields}
  ${DocumentFinanceStateFields}
  ${InvoiceLoanManagerBasicField}
  ${InvoiceLoanRosterBasic}
  ${FundBasicField}
  ${CreditNoteFields}
  ${MoneyFields}
  ${RiskBlacklistFields}
`;

export const GET_RISK_VARIABLES = gql`
  query getRiskVariables($objectId: Int! $modelName: String!) {
    getRiskVariables(objectId: $objectId, modelName: $modelName) {
      fetchVariables {
        id
        name
        value
        valueType
      }
    }
  }
`;

export const GET_RISK_PREDICTION_RESULTS = gql`
  query getRiskPredictionResults($riskEvaluationId: Int!) {
    getRiskPredictionResults(riskEvaluationId: $riskEvaluationId) {
      ... RiskPredictionResultTypeFragment
      predictionModel {
        id
        releasedDatetime
        predictionType {
          id
          resultEnum
        }
      }
      variablesContribution {
        ... RiskPredictionVariableContributionModelTypeFragment
      }
    }
  }
  ${RiskPredictionResultTypeFragment}
  ${RiskPredictionVariableContributionModelTypeFragment}
`;

export const GET_RISK_VARIABLES_DESCRIPTION = gql`
  query getRiskEvaluationVariablesDescription {
    getRiskEvaluationVariablesDescription {
      name
      labelName
      description
      variableType
    }
  }
`;

export const RERUN_EVALUATION_REQUEST = gql`
  mutation rerunEvaluationRequest (
    $objectId: Int!
    $objectType: String!
    $clientDicom: Boolean!
    $debtorDicom: Boolean!
    $clientBusinessProfile: Boolean!
    $debtorBusinessProfile: Boolean!
    $clientCurrentBehavior: Boolean!
    $debtorCurrentBehavior: Boolean!
    ) {
    rerunEvaluationRequest (
      objectId: $objectId
      objectType: $objectType
      clientDicom: $clientDicom
      debtorDicom: $debtorDicom
      clientBusinessProfile: $clientBusinessProfile
      debtorBusinessProfile: $debtorBusinessProfile
      clientCurrentBehavior: $clientCurrentBehavior
      debtorCurrentBehavior: $debtorCurrentBehavior
      ) {
      success
      }
    }
`;

export const GET_AUTOMATIC_RESPONSE_EMAIL_PREVIEW = gql`
  query getAutomaticResponseEmailPreview($documentIds: [String]!, $modelName: String!) {
    getAutomaticResponseEmailPreview(documentIds: $documentIds, modelName: $modelName) {
      preview
    }
  }
`;

export const GENERATE_RISK_PDF_LINK = gql`
  query generateRiskPdfLink(
    $invoiceIds: [Int]!) {
      generateRiskPdfLink(invoiceIds: $invoiceIds)
  }
`;

export const PREEVALUATION_EMAIL_SEND = gql`
  mutation GenerateAutomaticPreOfferResponseEmail(
    $documentsIds: [String]!
    $modelName: String!
    $acceptPreoffer: Boolean!
    $finalDescription: String
    $emailSending: String!
    $isPrivate: Boolean!) {
    generateAutomaticPreOfferResponseEmail(
      documentsIds: $documentsIds
      modelName: $modelName
      acceptPreoffer: $acceptPreoffer
      finalDescription: $finalDescription
      emailSending: $emailSending
      isPrivate: $isPrivate
      ) {
        success
    }
  }
`;

export const REJECT_ORDERING_SIMULATION = gql`
mutation rejectOrderingSimulation(
  $requestResolutions: [PurchaseOrderEvaluationResolutionRejectType], $sendEmail: Boolean!, $analystDescription: String
) {
  rejectOrderingSimulation (
    requestResolutions: $requestResolutions,
    sendEmail: $sendEmail,
    analystDescription: $analystDescription,
  ) {
    success
    }
  }
`;

export const APPROVE_ORDERING_SIMULATION = gql`
  mutation approveOrderingSimulation($requestResolutions: [SimulationApprovedType]!, $sendEmail: Boolean!, $analystDescription: String ) {
    approveOrderingSimulation(
      requestResolutions: $requestResolutions
      sendEmail: $sendEmail
      analystDescription: $analystDescription
      ) {
      success
    }
  }
`;

export const DOCUMENTS_TYPES = gql`
  query documentsTypes {
    documentsTypes {
      id
      code
      name
      shortName
      reviewDocumentManagerConstrain
    }
  }
`;

export const INVOICE_FILES = gql`
  query invoiceFiles(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          id
          folio
          amountWithIva {
            ...MoneyFields
          }
          ... InvoiceFilesFields
        }
      }

    }
  }
  ${TypeConnectionFields} 
  ${InvoiceFilesFields}
  ${MoneyFields}
`;

export const INVOICES_HISTORY_EVALUATIONS = gql`
query invoice(
  $preoffer_Preofferevaluationrequest_Status_In: [String]
  $orderBy: String
  $first: Int
  $companyId: Int
  $receiverId: Int
) {
  invoices(
    preoffer_Preofferevaluationrequest_Status_In: $preoffer_Preofferevaluationrequest_Status_In
    orderBy: $orderBy
    first: $first
    companyId: $companyId
    receiverId: $receiverId
  ) {
    totalCount
    totalPages
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        ... InvoiceIdFields
        dteType {
          ... InvoiceDteTypeFields
        }
        company {
          id
          masterEntity {
            ... MasterEntityBasicField
          }
          cessionsHistory {
            id
            cessionsNumber
            cessionsMoneyAmount
            historicCessionsNumber
            historicCessionsMoneyAmount
          }
        }
        receiver {
          ... MasterEntityBasicField
          receiverCessionsHistory {
            id
            cessionsNumber
            cessionsMoneyAmount
            historicCessionsNumber
            historicCessionsMoneyAmount
          }
        }
        companyReceiverCessionHistory {
          id
          cessionsNumber
          cessionsMoneyAmount
          historicCessionsNumber
          historicCessionsMoneyAmount
        }
        preoffer {
          id
          preofferevaluationrequest {
            ... PreofferEvaluationInfo
            user {
              ...UserIdentification
            }
            assignedEvaluator {
              ...UserIdentification
            }
          }
        }
      }
    }
  }
}
${InvoiceIdFields}
${InvoiceDteTypeFields}
${UserIdentification}
${PreofferEvaluationInfo}
${MasterEntityBasicField}
`;

export const CONFIRMINGS_QUERY = gql`
  query invoices(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          id
          relatedConfirming {
            id
            folio
            dateToPay
            createdAt
            amountWithIva {
              ... MoneyFields
            }
            advanceAmount {
              ... MoneyFields
            }
            company {
              id
              masterEntity {
                ... MasterEntityBasicField
                riskBlacklist {
                  ...RiskBlacklistFields
                }
              }
              companyevaluationrestrictionsSet {
                id
                status
                restriction
                description
                resolutionExplanation
              }
              documents {
                ... CompanyDocumentsFields
              }
            }
            receiver {
              id
              masterEntity {
                ... MasterEntityBasicField
                riskBlacklist {
                  ...RiskBlacklistFields
                }
              }
            }
            invoiceMoneyTransfer {
              id
              createdAt
            }
          }
        }
      }
    }
  }
  ${MoneyFields}
  ${TypeConnectionFields}
  ${MasterEntityBasicField}
  ${CompanyDocumentsFields}
  ${RiskBlacklistFields}
`;

export const MONTHLY_RATE_INVOICE = gql`
  query monthlyRateInvoice(
    $clientIdentifier: String!
    $debtorIdentifier: String!
    $utmCampaign: String!
    $encryptedMailLogId: String!
  ) {
    monthlyRateInvoice(
      clientIdentifier: $clientIdentifier
      debtorIdentifier: $debtorIdentifier
      utmCampaign: $utmCampaign
      encryptedMailLogId: $encryptedMailLogId
    ) {
      monthlyRate
      defaultRate
      retentionRate
      commissionRules {
        id
        rules {
          id
          threshold
          variant
          value
          expirationDate
        }
      }
    }
  }
`;

export const GET_INVOICES_FOR_CESSION_CHANGE_MAIL = gql`
  query GetInvoicesForCessionChangeMail(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          id
          status
          company {
            masterEntity {
              id
            }
          }
          documents {
            type {
              code
            }
          }
        }
      }
    }
  }
  ${TypeConnectionFields}
`;

export const GET_INVOICES_FOR_AUTOMATIC_OPERATION = gql`
  query GetInvoicesForAutomaticOperation(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
        ... TypeConnectionFields
        edges {
          cursor
          node {
            id
            folio
            receiver {
              id
              name
            }
            dateIssued
            dateExpiration
            dateToPay
            preoffer {
              ...PreofferFields
            }
            amountWithIva {
              ...MoneyFields
            }
          }
        }
      }
    }
  ${TypeConnectionFields}
  ${MoneyFields}
  ${PreofferFields}
`;

export const INVOICE_WITH_DOCUMENT = gql`
  query InvoiceWithDocument(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      totalCount
      totalPages
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          folio
          documents {
            ... InvoiceDocumentsFields
          }
        }
      }
    }
  }
  ${InvoiceDocumentsFields}
`;

export const INVOICE_CESSIONS_TOOLTIP = gql`
  query invoiceCessionsTooltip (
    $invoiceId: ID!
  ) {
    invoice(
      invoiceId: $invoiceId
    ) {
      id
      company {
        id
        cessionsHistory {
          ... CompanyCessionsHistoryFields
        }
      }
      receiver {
        id
        receiverCessionsHistory {
          ... ReceiverCessionsHistoryFields
        }
      }
      companyReceiverCessionHistory {
        ... CompanyReceiverCessionsFields
      }
    }
  }
  ${CompanyCessionsHistoryFields}
  ${ReceiverCessionsHistoryFields}
  ${CompanyReceiverCessionsFields}
`;

export const CLIENT_FACTORING_INFORMATION = gql`
  query clientFactoringInformation (
    $companyId: Int
    $receiverId: Int
  ) {
    exposedInvoices: invoices(
      companyId: $companyId
      receiverId: $receiverId
      status: ["Debt", "Transfered"]
      first: 1
    ) {
      totalCount
      aggregate(field: "amount_with_iva")
      edges {
        cursor
        node {
          id
        }
      }
    }
    debtInvoices: invoices(
      companyId: $companyId
      receiverId: $receiverId
      status: ["Debt"]
      first: 1
    ) {
      totalCount
      aggregate(field: "amount_with_iva")
      edges {
        cursor
        node {
          id
        }
      }
    }
    finishedInvoices: invoices(
      companyId: $companyId
      receiverId: $receiverId
      status: ["Finished"]
      first: 1
    ) {
      totalCount
      aggregate(field: "amount_with_iva")
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const CLIENT_INVOICES_STATUS_INFORMATION = gql`
  query clientInvoicesStatusInformation(
    $companyId: Int
    $receiverId: Int
    $dateIssued_Gte: Date
  ) {
    amountRejectedInvoices: invoices(
      companyId: $companyId
      receiverId: $receiverId
      dateIssued_Gte: $dateIssued_Gte
      siiStatus: ["Rejected"]
      first: 1
    ) {
      totalCount
      edges {
        cursor
        node {
          id
        }
      }
    }
    amountCreditNoteInvoices: invoices(
      companyId: $companyId
      receiverId: $receiverId
      dateIssued_Gte: $dateIssued_Gte
      dteType_Code: "61"
      first: 1
    ) {
      totalCount
      edges {
        cursor
        node {
          id
        }
      }
    }
    amountOfPendingReintegrations: invoices(
      companyId: $companyId
      receiverId: $receiverId
      availableForReintegration: true
      first: 1
    ) {
      totalCount
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const FACTORING_PROFILE_INVOICES = gql`
  query factoringProfileInvoices(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      totalCount
      totalPages
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ... InvoiceIdFields
          ... InvoiceStatusFields
          company {
            id
            masterEntity {
              ... MasterEntityBasicField
            }
          }
          receiver {
            ... MasterEntityBasicField
          }
          dteType {
            ... InvoiceDteTypeFields
          }
          historystatusSet {
            ... InvoiceHistoryStatusFields
          }
          uniqueDocumentFinanceState {
            ... DocumentFinanceStateFields
          }
          collectionManager {
            id
            actions {
              ... CollectionActionsFields
              author {
                ... UserIdentification
              }
            }
          }
          ratificationmanager {
            id
            actions {
              ... RatificationActionsBasicFields
              author {
                ... UserIdentification
              }
            }
          }
          amountUsedInEntityLine {
            ... MoneyFields
          }
        }
      }
    }
  }
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${InvoiceDteTypeFields}
  ${MasterEntityBasicField}
  ${InvoiceHistoryStatusFields}
  ${DocumentFinanceStateFields}
  ${CollectionActionsFields}
  ${RatificationActionsBasicFields}
  ${UserIdentification}
  ${MoneyFields}
`;

export const GET_INVOICE_QUANTITIES = gql`
query getInvoiceQuantities(
  ${sharedInvoiceInputFields}
) {
  invoices(
    ${sharedInvoiceInput}
  ) {
    totalCount
  }
}
`;

export const INVOICES_RELATED_COMPANY_ANNEX = gql`
  query GetSimpleInvoice($invoiceId: ID!) {
    invoice(invoiceId: $invoiceId) {
      id
      pendingsignaturecontractannexinvoice {
        id
        pendingSignatureDocument {
          id
          invoices {
            edges {
              cursor
              node {
                id
                folio
                dateIssued
                receiver {
                  ... MasterEntityBasicField
                }
                amountWithIva {
                  ...MoneyFields
                }
              }
            }
          }
        }
      }
    }
  }
  ${MasterEntityBasicField}
  ${MoneyFields}
`;

export const INVOICE_FOLIOS = gql`
  query invoiceFiles(
    ${sharedInvoiceInputFields}
  ) {
    invoices(
      ${sharedInvoiceInput}
    ) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          id
          folio
        }
      }

    }
  }
  ${TypeConnectionFields} 
`;
