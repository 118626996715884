import { gql } from '@apollo/client';

export const InvoiceLoanManagerBasicField = gql`
  fragment InvoiceLoanManagerBasicField on InvoiceLoanManagerType {
    id
  }
`;

export const InvoiceLoanRosterBasic = gql`
  fragment InvoiceLoanRosterBasic on InvoiceLoanRosterType {
    id
  }
`;

export const FundBasicField = gql`
  fragment FundBasicField on FundType {
    id
    email
    active
    isFund
    isAsignee
  }
`;
