/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { WhatsappLogFields } from './fragments';

export const NEW_WHATSAPP_MESSAGE = gql`
  subscription newWhatsappMessage($conversationHash: String!, $twilioSenderId: Int!) {
    newWhatsappMessage(conversationHash: $conversationHash, twilioSenderId: $twilioSenderId) {
      whatsappLog {
        ...WhatsappLogFields
      }
    }
  }
  ${WhatsappLogFields}
`;
