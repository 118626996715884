/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const RETRIEVE_INVOICE_LOAN_TREASURY_MESSAGE = gql`
  query retrieveInvoiceLoanTreasuryMessage($id: String!) {
    retrieveInvoiceLoanTreasuryMessage(id: $id) {
      message
    }
  }
`;
