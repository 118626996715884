import { gql } from '@apollo/client';
import { MoneyFields } from '../moneyFields';
import {
  EvaluationRequestFields,
  InvoiceDocumentsFields,
  InvoiceIdFields,
  InvoiceStatusFields,
  PreofferFields,
  TraceFields,
} from './fragments';

export const UPLOAD_INVOICE_DOCUMENTS = gql`
  mutation uploadInvoiceDocuments(
    $invoiceId: Int!
    $inputUploadFiles: [UploadFilesInvoiceDocumentManagerInputType]!
  ) {
    uploadInvoiceDocuments(
      invoiceId: $invoiceId
      inputUploadFiles: $inputUploadFiles
    ) {
      invoices {
        totalCount
        totalPages
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          node {
            id
            documents {
              ...InvoiceDocumentsFields
            }
          }
        }
      }
    }
  }
  ${InvoiceDocumentsFields}
`;

export const UPLOAD_INVOICE_SIGNATURE = gql`
  mutation uploadInvoiceSignature(
    $invoiceIds: [Int]!
    $inputUploadFile: Upload!
  ) {
    uploadInvoiceSignature(
      invoiceIds: $invoiceIds
      inputUploadFile: $inputUploadFile
    ) {
      invoices {
        id
        status
      }
    }
  }
`;

export const UPLOAD_DOCUMENT_BATCH = gql`
  mutation UploadDocumentBatch(
    $invoiceIds: [Int]!
    $inputUploadFiles: [UploadFilesInvoiceDocumentManagerInputType]!
  ) {
    uploadDocumentBatch(
      invoiceIds: $invoiceIds
      inputUploadFiles: $inputUploadFiles
    ) {
      documentBatch {
        id
      }
    }
  }
`;

export const LOAD_NEW_INVOICES = gql`
  mutation LoadNewInvoices($companyId: Int!) {
    loadNewInvoices(companyId: $companyId) {
      taskId
    }
  }
`;

export const FILE_URL = gql`
  mutation invoicePdfUrl($invoiceId: Int!) {
    invoicePdfUrl(invoiceId: $invoiceId) {
      invoice {
        id
        documents {
          ...InvoiceDocumentsFields
        }
      }
    }
  }
  ${InvoiceDocumentsFields}
`;

export const LOAD_OFFERS_MISSING_XML = gql`
  mutation loadOffersMissingXml($invoiceIds: [Int]!) {
    loadOffersMissingXml(invoiceIds: $invoiceIds) {
      success
    }
  }
`;

export const UPLOAD_INVOICING_INSTRUCTIONS = gql`
  mutation uploadInvoicingInstructions($companyId: Int!, $file: Upload!) {
    uploadInvoicingInstructions(companyId: $companyId, file: $file) {
      message
    }
  }
`;

export const CREATE_OPERATION = gql`
  mutation CreateOperation(
    $assignmentRequests: [AssignmentRequestType]
    $bankAccountId: Int!
    $isExternal: Boolean!
    $companyId: Int!
    $digitalCertificatePassword: String
    $requestingPlatformId: Int
  ) {
    createOperation(
      assignmentRequests: $assignmentRequests
      bankAccountId: $bankAccountId
      isExternal: $isExternal
      companyId: $companyId
      digitalCertificatePassword: $digitalCertificatePassword
      requestingPlatformId: $requestingPlatformId
    ) {
      response
    }
  }
`;

export const CREATE_AUTOMATIC_CESSION_CONFIG = gql`
  mutation CreateAutomaticCessionConfig(
    $automaticCessionInfos: [AutomaticCessionInfo]!
    $bankAccountId: Int!
    $companyId: Int!
    $cessionOption: String!
    $ratificationOption: String!
    $notRatificationOption: String!
    $email: String
    $phoneNumber: String
  ) {
    createAutomaticCessionConfig(
      automaticCessionInfos: $automaticCessionInfos
      bankAccountId: $bankAccountId
      companyId: $companyId
      cessionOption: $cessionOption
      ratificationOption: $ratificationOption
      notRatificationOption: $notRatificationOption
      email: $email
      phoneNumber: $phoneNumber
    ) {
      success
      message
      automaticCessionConfig {
        id
      }
    }
  }
`;

export const REQUEST_PRE_OFFERS_EVALUATION = gql`
  mutation RequestPreOfferEvaluation(
    $requestingPlatformId: Int
    $invoiceIds: [ID]!
  ) {
    requestPreOfferEvaluation(
      requestingPlatformId: $requestingPlatformId
      invoiceIds: $invoiceIds
    ) {
      invoicesEvaluated {
        ...InvoiceIdFields
        preoffer {
          ...PreofferFields
          preofferevaluationrequest {
            ...EvaluationRequestFields
          }
        }
      }
    }
  }
  ${InvoiceIdFields}
  ${PreofferFields}
  ${EvaluationRequestFields}
`;

export const REQUEST_PRE_OFFERS_EVALUATION_BY_HASH = gql`
  mutation requestPreOfferEvaluationByHash($preofferHash: String!) {
    requestPreOfferEvaluationByHash(preofferHash: $preofferHash) {
      success
    }
  }
`;

export const CHANGE_ASSIGNMENT_CESSION_TYPE = gql`
  mutation ChangeAssignmentCessionType(
    $invoiceHash: String!
    $status: String!
  ) {
    changeAssignmentCessionType(invoiceHash: $invoiceHash, status: $status) {
      assignments {
        id
        cessionType
      }
    }
  }
`;

export const RUN_AUTOMATIC_CESSION = gql`
  mutation RunAutomaticCession($automaticCessionConfigHash: String!) {
    runAutomaticCession(
      automaticCessionConfigHash: $automaticCessionConfigHash
    ) {
      success
    }
  }
`;

export const FETCH_TRACE = gql`
  mutation fetchTrace($invoiceId: Int!) {
    fetchTrace(invoiceId: $invoiceId) {
      invoice {
        ...InvoiceIdFields
        ...InvoiceStatusFields
        traces {
          ...TraceFields
        }
        intermediateReferencesFrom {
          toInvoice {
            ...InvoiceIdFields
          }
          amount {
            ...MoneyFields
          }
        }
        intermediateReferencesTo {
          fromInvoice {
            ...InvoiceIdFields
          }
          amount {
            ...MoneyFields
          }
        }
      }
    }
  }
  ${MoneyFields}
  ${TraceFields}
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
`;

export const CREATE_REINTEGRATION = gql`
  mutation CreateReintegration(
    $owedInvoicesIds: [Int]!
    $payingInvoicesIds: [Int]!
  ) {
    createReintegration(
      owedInvoicesIds: $owedInvoicesIds
      payingInvoicesIds: $payingInvoicesIds
    ) {
      success
    }
  }
`;

export const CREATE_REPLACEMENTS = gql`
  mutation CreateReplacements(
    $replacedInvoiceIds: [Int]!
    $replacingInvoiceIds: [Int]!
    $useSurplusForDifference: Boolean
  ) {
    createReplacements(
      replacedInvoiceIds: $replacedInvoiceIds
      replacingInvoiceIds: $replacingInvoiceIds
      useSurplusForDifference: $useSurplusForDifference
    ) {
      replacements {
        id
      }
    }
  }
`;

export const FETCH_MANY_TRACE = gql`
  mutation fetchManyTrace($invoiceId: [Int]!) {
    fetchManyTrace(invoiceId: $invoiceId) {
      invoices {
        ...InvoiceIdFields
        ...InvoiceStatusFields
        traces {
          ...TraceFields
        }
      }
    }
  }
  ${InvoiceIdFields}
  ${InvoiceStatusFields}
  ${TraceFields}
`;

export const ASSIGN_EVALUATOR = gql`
  mutation assignEvaluator(
    $documentId: Int!
    $modelName: String!
    $assign: Boolean!
  ) {
    assignEvaluator(
      documentId: $documentId
      modelName: $modelName
      assign: $assign
    ) {
      success
    }
  }
`;

export const REEXECUTE_INVOICE_ACTION = gql`
  mutation reexecuteInvoiceAction(
    $invoicesIds: [String]!
    $reexecutionType: String!
    $reason: String!
    $comments: String
    $rut: String
  ) {
    reexecuteInvoiceAction(
      invoicesIds: $invoicesIds
      reexecutionType: $reexecutionType
      reason: $reason
      comments: $comments
      rut: $rut
    ) {
      success
    }
  }
`;

export const ADD_OR_EDIT_WHITELIST = gql`
  mutation AddOrEditWhitelist(
    $emitterRut: String
    $receiverRut: String
    $whitelistId: Int
    $monthlyRate: Float
    $defaultRate: Float
    $retentionRate: Float
    $active: Boolean
  ) {
    addOrEditRateWhitelist(
      emitterRut: $emitterRut
      receiverRut: $receiverRut
      whitelistId: $whitelistId
      monthlyRate: $monthlyRate
      defaultRate: $defaultRate
      retentionRate: $retentionRate
      active: $active
    ) {
      rateWhitelist {
        id
      }
    }
  }
`;

export const GENERATE_PENDING_SIGNATURE_CONTRACT_ANNEX = gql`
  mutation createPendingSignatureContractAnnex(
    $signatureDate: Date!
    $companyId: String!
    $companyContractName: String!
    $invoiceIds: [String]!
    $bankAccountId: String!
    $legalRepresentatives: [CreatePendingSignatureContractAnnexPersonInput]!
    $endorsements: [CreatePendingSignatureContractAnnexPersonInput]
  ) {
    createPendingSignatureContractAnnex(
      signatureDate: $signatureDate
      companyId: $companyId
      companyContractName: $companyContractName
      invoiceIds: $invoiceIds
      bankAccountId: $bankAccountId
      legalRepresentatives: $legalRepresentatives
      endorsements: $endorsements
    ) {
      contractAnnex {
        id
        globalAppId
        createdAt
        lastFile
        paybackFile
      }
    }
  }
`;

export const DELETE_RELATED_ANNEX = gql`
  mutation DeleteRelatedAnnex($annexId: Int!) {
    deletePendingSignatureContractAnnex(annexId: $annexId) {
      response
    }
  }
`;
