/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const NEW_INVOICES_CHARGED = gql`
  subscription newInvoiceCharged($companyId: Int!) {
    newInvoiceCharged(companyId: $companyId){
      newInvoices
    }
  }
`;
