import { gql } from '@apollo/client';

export const UPLOAD_ROSTER_FILE = gql`
  mutation UploadRosterFile($file: Upload!, $fundId: Int!, $cessionareId: Int!) {
    uploadRosterFile(file:$file, fundId: $fundId, cessionareId: $cessionareId) {
      roster {
        id
      }
    }
  }
`;

export const UPLOAD_ROSTER_INVOICES_TO_MONEY_TRANSFER = gql`
  mutation uploadRosterInvoicesToMoneyTransfer($file: Upload!) {
    uploadRosterInvoicesToMoneyTransfer(file:$file) {
      moneyTransfer {
        id
      }
    }
  }
`;

export const REQUEST_INVOICE_ROSTER_RECESION = gql`
  mutation requestInvoiceRosterRecesion($file: Upload!) {
    requestInvoiceRosterRecesion(file:$file) {
      assignments {
        id
      }
    }
  }
`;

export const ROSTER_RECESION = gql`
  mutation RosterRecesion($rosterId: Int!) {
    rosterRecesion(rosterId: $rosterId) {
      roster {
        id
        status
      }
    }
  }
`;

export const DELETE_INVOICES_FROM_ROSTER = gql`
  mutation DeleteInvoicesFromRoster($documentIds: [String]!) {
    deleteInvoicesFromRoster(documentIds: $documentIds) {
      success
    }
  }
`;

export const ADD_INVOICE_TO_ROSTER = gql`
  mutation AddInvoiceToRoster(
    $documentId: Int!
    $rate: Float!
    $retentionRate: Float!
    $expirationDate: Date!
    $rosterId: Int!
  ) {
    addInvoiceToRoster(
      documentId: $documentId
      rate: $rate
      retentionRate: $retentionRate
      expirationDate: $expirationDate
      rosterId: $rosterId
    ) {
      invoice {
        id
      }
    }
  }
`;

export const UPLOAD_PAYROLL_CONCILIATION_FILE = gql`
  mutation uploadPayrollConciliationFile(
    $file: Upload!
  ) {
    uploadPayrollConciliationFile(
      file: $file
    ) {
      collectionManagers {
        id
        status
      }
    }
  }
`;

export const UPLOAD_BANK_STATEMENT = gql`
  mutation uploadBankStatement(
    $file: Upload!
    $countryId: String!
  ) {
    uploadBankStatement(
      file: $file
      countryId: $countryId
    ) {
      bankMovements {
          id
        }
      }
    }
`;

export const FETCH_ASSIGNMENT_CERTIFICATE = gql`
  mutation fetchAssignmentCertificate(
    $id: String!
  ) {
    fetchAssignmentCertificate(
      id: $id
    ) {
      url
    }
  }
`;

export const FETCH_ROSTER_CESSION_CERTIFICATE = gql`
mutation fetchRosterCessionCertificate(
  $id: String!
) {
  fetchRosterCessionCertificate(
    id: $id
  ) {
    url
  }
}
`;

export const FETCH_INVOICE_DOCUMENT = gql`
  mutation fetchInvoiceDocument(
    $id: String!
  ) {
    fetchInvoiceDocument(
      id: $id
    ) {
      url
    }
  }
`;
